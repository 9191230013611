<template>
  <echarts-com v-loading="loading" id="userPreference-userAmount" :options="options"/>
</template>

<script>

export default {
  components: {
    echartsCom: () => import("@/components/echartsCom")
  },
  data() {
    return {
      loading: false,
      options: {},
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    async getOptions(params={}) {
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/user/new/amount", params);
        if (res.code === 0) {
          this.$set(this, "options", {
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                type: 'pie',
                radius: ['50%', '70%'],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderWidth: 100
                },
                label: {
                  show: true,
                  position: 'center',
                  color: 'white',
                  formatter: `{total|${res.data}} 人`,
                  rich: {
                    total: {
                      fontSize: 24,
                      color: 'white'
                    },
                  }
                },
                data: [
                  {
                    value: res.data,
                  }
                ]
              }
            ]
          })
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch {
        this.$message.error("停车场人为抬杆次数获取失败");
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
#userPreference-userAmount {

}
</style>
